.btn-primaryi {
    background-color: none;
    border: 2rem;
    
  }
/* CustomButton.css */

.custom-button {
  /* Base styles */
  font-size: 16px;
  padding: 8px 16px;
  transition: all 0.3s ease; /* Optional: add transition effect for smooth resizing */

  /* Responsive adjustments */
  @media (max-width: 576px) {
    font-size: 14px; /* Reduce font size for smaller screens */
    padding: 6px 12px; /* Adjust padding for smaller screens */
  }

  @media (max-width: 400px) {
    font-size: 12px; /* Further reduce font size for very small screens */
    padding: 4px 8px; /* Adjust padding for very small screens */
  }
}