.site-footer {
  position: relative;
  bottom:0;
  width: 100%;
  background-color: #26272b;
  padding: 45px 0 20px;
  font-size: 15px;
  line-height: 24px;
  color: #737373;
  overflow-x: hidden; /* Hide horizontal scrollbar */
}




.site-footer hr {
  border-top-color: #bbb;
  opacity: 0.5;
}

.site-footer hr.small {
  margin: 20px 0;
}

.site-footer h6 {
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  margin-top: 5px;
  letter-spacing: 2px;
}

.site-footer a {
  color: #737373;
}

.site-footer a:hover {
  color: #3366cc;
  text-decoration: none;
}

.footer-links {
  padding-left: 0;
  list-style: none;
}

.footer-links li {
  display: block;
}

.footer-links a {
  color: #737373;
}

.footer-links a:active,
.footer-links a:focus,
.footer-links a:hover {
  color: #3366cc;
  text-decoration: none;
}

.footer-links.inline li {
  display: inline-block;
}


.copyright-text {
  margin: 0;
}

@media (max-width: 767px) {
  .container1 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .row {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .col-sm-12,
  .col-md-6,
  .col-xs-6,
  .col-md-3,
  .col-md-8,
  .col-sm-6 {
    text-align: center;
    margin-bottom: 20px;
  }

  .col-md-6,
  .col-md-3,
  .col-md-8 {
    order: 2;
  }
}
