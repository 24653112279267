/* EtatSmsSend.css */

/* Style pour le conteneur principal */
.container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Style pour le titre principal */
h1 {
  margin: 0;
  padding-bottom: 20px; /* Ajout d'un espace sous le titre */
}

/* Style pour le groupe de formulaire et espacement */
.form-group {
  margin-bottom: 40px; /* Espacement de deux lignes */
  text-align: center;
  width: 100%;
}

/* Style pour le groupe d'entrée */
.input-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Style pour les labels */
label {
  margin-bottom: 10px;
  font-size: 18px;
}

/* Style pour les champs de texte */
input[type="text"] {
  padding: 8px;
  margin-bottom: 20px; /* Ajout d'un espace sous le champ de saisie */
  width: 100%;
  max-width: 300px;
}

/* Style pour les boutons */
button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

/* Style pour les messages d'erreur */
.error {
  color: red;
  margin-top: 20px;
}

/* Style pour le conteneur de la table */
.table-container {
  width: 100%;
  max-width: 1200px;
  overflow-x: auto;
}

/* Style pour le tableau */
.styled-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 16px;
}

/* Style pour les en-têtes de table */
.styled-table thead tr {
  background-color: #007bff;
  color: white;
}

/* Style pour les cellules d'en-tête */
.styled-table th, .styled-table td {
  padding: 12px 15px;
}

/* Style pour les cellules d'en-tête spécifiques */
.styled-table th {
  text-align: center; /* Centrage du texte */
}

/* Style pour la première cellule d'en-tête avec retour à la ligne */
.styled-table th:first-child {
  white-space: normal; /* Permet le retour à la ligne */
  word-wrap: break-word; /* Assure que les mots longs se cassent si nécessaire */
  text-align: center; /* Centre le texte */
}

/* Style pour les lignes du tableau */
.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #007bff;
}

/* Style pour les écrans plus petits */
@media (max-width: 768px) {
  .styled-table {
    font-size: 14px;
  }

  .input-group {
    flex-direction: column;
  }

  input[type="text"] {
    width: 100%;
    margin-bottom: 20px; /* Ajout d'un espace sous le champ de saisie */
  }
}
