.body {
    background:linear-gradient(90deg, hsla(270, 94%, 25%, 1) 0%, hsla(158, 94%, 49%, 1) 100%);
    transition: all 0.5s;
    padding: 1px;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .veen {
    width: 70%;
    margin: 100px auto;
    background: rgba(255, 255, 255, 0.5);
    min-height: 400px;
    display: table;
    position: relative;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
  }
  
  .veen>div {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    color: #fcfcfc;
  }
  
  .veen .dark-d {
    background: transparent;
    /* background-image: linear-gradient(90deg, #e0b722, #ff4931); */
    display: inline-block;
    padding: 10px 30px;
    border: 3px solid #fff;
    border-radius: 50px;
    background-clip: padding-box;
    position: relative;
    color: #fff;
    /* box-shadow: 0 0 4px rgba(0,0,0,.14), 0 4px 8px rgba(0,0,0,.28); */
    transition: all 0.25s;
  }
  
  .veen button.dark-d {
    border-color: linear-gradient(90deg, hsla(158, 94%, 49%, 1) 0%, hsla(270, 94%, 25%, 1) 100%);
    background: linear-gradient(90deg, hsla(158, 94%, 49%, 1) 0%, hsla(270, 94%, 25%, 1) 100%);
  }
  
  .veen .move button.dark-d {
    border-color:linear-gradient(90deg, hsla(270, 94%, 25%, 1) 0%, hsla(158, 94%, 49%, 1) 100%);
    background: linear-gradient(90deg, hsla(270, 94%, 25%, 1) 0%, hsla(158, 94%, 49%, 1) 100%);
  }
  
  .veen .splits p {
    font-size: 18px;
  }
  
  .veen .dark-d:active {
    box-shadow: none;
  }
  
  .veen .dark-d:focus {
    outline: none;
  }
  
  .veen>.wrapper-f {
    position: absolute;
    width: 40%;
    height: 120%;
    top: -10%;
    left: 5%;
    background: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
    transition: all 0.5s;
    color: #303030;
    overflow: hidden;
  }
  
  .veen .wrapper-f>form {
    padding: 15px 30px 30px;
    width: 100%;
    transition: all 0.5s;
    background: #fff;
    width: 100%;
  }
  
  .veen .wrapper-f>form:focus {
    outline: none;
  }
  
  .veen .wrapper-f #login {
    padding-top: 20%;
    visibility: visible;
  }
  
  .veen .wrapper-f #register {
    transform: translateY(-80%) translateX(100%);
    visibility: hidden;
  }
  
  .veen .wrapper-f.move #register {
    transform: translateY(-80%) translateX(0%);
    visibility: visible;
  }
  
  .veen .wrapper-f.move #login {
    transform: translateX(-100%);
    visibility: hidden;
  }
  
  .veen .wrapper-f>form>div {
    position: relative;
    margin-bottom: 15px;
  }
  
  .veen .wrapper-f label {
    position: absolute;
    top: -7px;
    font-size: 12px;
    white-space: nowrap;
    background: #fff;
    text-align: left;
    left: 15px;
    padding: 0 5px;
    color: #999;
    pointer-events: none;
  }
  
  .veen .wrapper-f h3 {
    margin-bottom: 25px;
  }
  
  .veen .wrapper-f input {
    height: 40px;
    padding: 5px 15px;
    width: 100%;
    border: solid 1px #999;
  }
  
  .veen .wrapper-f input:focus {
    outline: none;
    border-color: linear-gradient(90deg, hsla(158, 94%, 49%, 1) 0%, hsla(270, 94%, 25%, 1) 100%);
  }
  
  .veen>.wrapper-f.move {
    left: 45%;
  }
  
  .veen>.wrapper-f.move input:focus {
    border-color: linear-gradient(90deg, hsla(270, 94%, 25%, 1) 0%, hsla(158, 94%, 49%, 1) 100%);
  }
  
  @media (max-width: 767px) {
    .veen {
      padding: 5px;
      margin: 0;
      width: 100%;
      display: block;
    }
    .veen>.wrapper-f {
      position: relative;
      height: auto;
      top: 0;
      left: 0;
      width: 100%;
    }
    .veen>div {
      display: inline-block;
    }
    .splits {
      width: 50%;
      background: #fff;
      float: left;
    }
    .splits .dark-d {
      width: 100%;
      border-radius: 0;
      background: #505050;
      border: 0;
      opacity: 0.7;
    }
    .splits .dark-d.active {
      opacity: 1;
    }
    .splits .dark-d.active {
      opacity: 1;
      background: linear-gradient(90deg, hsla(270, 94%, 25%, 1) 0%, hsla(158, 94%, 49%, 1) 100%);
    }
    .splits.rgstr-btn .dark-d.active {
      background:linear-gradient(90deg, hsla(158, 94%, 49%, 1) 0%, hsla(270, 94%, 25%, 1) 100%);
    }
    .splits p {
      display: none;
    }
    .veen>.wrapper-f.move {
      left: 0%;
    }
  }
  
  input:-webkit-autofill,
  textarea:-webkit-autofill,
  select:-webkit-autofill {
    box-shadow: inset 0 0 0 50px #fff;
  }
  