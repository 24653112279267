.alert-container {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 500px;
  z-index: 1050; /* Assurez-vous que l'alerte est au-dessus des autres éléments */
}

.confirmation-code-container {
    display: flex;
    align-items: center;
    justify-content: center; /* Center horizontally */
  }
  
  .input-container {
    display: flex;
  }
  
  .confirmation-code-input {
    width: 35px;
    height: 45px;
    margin-right: 5px; /* Add margin right for spacing between inputs */
    text-align: center;
    font-size: 16px;
    border: 2px solid rgb(7, 6, 6);
    color: rgb(0, 0, 0); /* Set text color to white */
    background-color: transparent; /* Set background color to transparent */
    border-radius: 8px;
  }
  
  .confirmation-code-input:focus {
    outline: none; /* Remove focus outline */
  }
  
  .confirmation-code-input::placeholder {
    color: white; /* Set placeholder color to white */
  }
  
  .confirmation-code-input:last-child {
    margin-right: 0; /* Remove margin right for the last input */
  }
  
  button {
    height: 40px;
    margin-left: 10px;
  }
  