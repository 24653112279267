  
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height:60vh;
  }
  
  .login-container {
    max-width: 400px;
    width: 100%;
  }
  
  .login-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 28px;
    color: #28a745;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-control {
    border-radius: 5px;
  }
  
  .btn-primary {
    background-color: #28a745;
    border: none;
  }
  
  .btn-primary:hover {
    background-color: #218838;
  }
  
  .register-link {
    text-align: center;
    margin-top: 20px;
  }
  
  .register-link a {
    color: #28a745;
    text-decoration: none;
    font-weight: bold;
  }
  
  .register-link a:hover {
    text-decoration: underline;
  }

  .password-input-container {
    display: flex;
    align-items: center;
  }
  
  .password-toggle-icon {
    cursor: pointer;
    margin-left: 10px; /* Adjust the margin as needed */
  }
  