.carousel-container {
    position: relative;
}



.carousel-caption {
    position: absolute;
    top: 60%;
    left: 50%;

    transform: translate(-50%, -50%);
    color: #fff;
    /* White text */
    font-size: 24px;
    /* Adjust as needed */
    text-align: center;
    width: 100%;

}

@media (max-width: 767px) {
    .carousel-item img {
        height: auto;
        /* Make the image responsive */
        max-height: 400px;
        /* Set a maximum height for mobile devices */
    }
}


/* Number counter Css */
.wrapper {
    max-width: 980px;
    margin: 30px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.counter {
    flex: 1;
    min-width: calc(23.5% - 20px); /* Adjust as needed */
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .counter {
        flex: 0 0 100%;
        min-width: 100%;
    }
}

.carousel-control-prev,
.carousel-control-next {
    display: none;
}
.carousel-indicators {
    display: none;
}
