.container50 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.item {
    width: calc(50% - 10px); /* 50% width with some space in between */
    margin-bottom: 20px; /* Some margin between items */
}

@media (max-width: 768px) {
    .item {
        width: 100%; /* Full width on smaller screens */
    }
}