/* Define colors */
:root {
    --bg-blue: #337ab7;
    --bg-hoki: #67809F;
    --bg-red: #e7505a;
    --bg-purple: #8E44AD;
  }
  
  
  
  .wrapper {
    position: relative;
  }
  
 
  
  .dashboard-stat {
    position: relative;
    display: block;
    margin: 0 5 5px;
    overflow: hidden;
    border-radius: 4px;
  }
  
  .dashboard-stat .visual {
    width: 950px;
    height: 100px;
    display: block;
    float: left;
    padding-top: 10px;
    padding-left: 15px;
    margin-bottom: 15px;
    padding-right: 15px;
    font-size: 35px;
    line-height: 35px;
  }
  
  .dashboard-stat .visual > i {
    margin-left: -15px;
    font-size: 110px;
    line-height: 110px;
    color: #fff;
    opacity: 0.1;
  }
  
  .dashboard-stat .details {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto; /* Center the text */
    color: #fff;
    text-align: center; /* Center the text horizontally */
  }
  
  
  
  .dashboard-stat .details .desc {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center the text */
    text-transform: capitalize;
    font-size: 22px;
    letter-spacing: 0;
    font-weight: 300;
  }
  
  
  .dashboard-stat.blue {
    background-color: var(--bg-blue);
  }
  
  .dashboard-stat.red {
    background-color: var(--bg-red);
  }
  
  .dashboard-stat.purple {
    background-color: var(--bg-purple);
  }
  
  .dashboard-stat.hoki {
    background-color: var(--bg-hoki);
  }
  