.container-signup {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 120px;
  height: 80vh; /* 80% of the viewport height */
}

.signup-container {
  max-width: 400px;
  width: 100%;
  height: 100%;
  overflow-y: auto; /* Allow vertical scroll */
  padding-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add shadow */
  border-radius: 10px; /* Optional: Rounded corners */
}

.signup-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 28px;
  color: #28a745;
}

.form-group {
  margin-bottom: 20px;
}

.form-control {
  border-radius: 5px;
}

.btn-primary {
  background-color: #28a745;
  border: none;
}

.btn-primary:hover {
  background-color: #218838;
}

.login-link {
  text-align: center;
  margin-top: 20px;
}

.login-link a {
  color: #28a745;
  text-decoration: none;
  font-weight: bold;
}

.login-link a:hover {
  text-decoration: underline;
}

.password-group {
  display: flex;
  align-items: center;
}

.password-toggle-icon {
  cursor: pointer;
  margin-left: 5px;
}

/* Ensure sufficient margins between form groups */
.mb-4 {
  margin-bottom: 20px;
}
