/* ComingSoon.css */
body {
    margin: 0;
    padding: 0;
  }
  
  * {
    box-sizing: border-box;
  }
  
  .maintenance {
    background-image: url(https://demo.wpbeaveraddons.com/wp-content/uploads/2018/02/main-1.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: scroll;
    background-size: cover;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }
  
  .maintenance_contain {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%; /* Adjust the width based on your design preference */
    max-width: 500px; /* Adjust the max-width based on your design preference */
    padding: 15px;
  }
  
  .maintenance_contain img {
    width: 100%;
    max-width: 100%;
  }
  
  .pp-infobox-title-prefix {
    font-weight: 500;
    font-size: 20px;
    color: #000000;
    margin-top: 5px; /* Adjust the margin based on your design preference */
    text-align: center;
  }
  
  .pp-infobox-title {
    color: #000000;
    font-weight: 700;
    font-size: 30px; /* Adjust the font-size based on your design preference */
    margin-top: 10px;
    margin-bottom: 5px;
    text-align: center;
    word-break: break-word;
  }
  
  .pp-infobox-description {
    color: #000000;
    font-weight: 400;
    font-size: 16px; /* Adjust the font-size based on your design preference */
    margin-top: 0px;
    margin-bottom: 15px; /* Adjust the margin based on your design preference */
    text-align: center;
  }
  
  .pp-infobox-description p {
    margin: 0;
  }
  
  /* Add responsive styles below */
  @media screen and (max-width: 768px) {
    .maintenance_contain {
      width: 90%; /* Adjust the width for smaller screens */
    }
  
    .pp-infobox-title {
      font-size: 24px; /* Adjust the font-size for smaller screens */
    }
  
    .pp-infobox-description {
      font-size: 14px; /* Adjust the font-size for smaller screens */
    }
  }
  