.body-Approv {
    background:  linear-gradient(90deg, hsla(134, 87%, 42%, 1) 5%, hsla(56, 97%, 56%, 1) 86%);
    transition: all 0.5s;
    padding: 1px;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .veen {
    width: 70%;
    margin: 100px auto;
    background: rgba(136, 130, 130, 0.5);
    min-height: 400px;
    display: table;
    position: relative;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
  }
  
  .veen>div {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    color: #fff;
  }
  
  .veen button {
    background: transparent;
    /* background-image: linear-gradient(90deg, #e0b722, #ff4931); */
    display: inline-block;
    padding: 10px 30px;
    border: 3px solid #fff;
    border-radius: 50px;
    background-clip: padding-box;
    position: relative;
    color: #fff;
    /* box-shadow: 0 0 4px rgba(0,0,0,.14), 0 4px 8px rgba(0,0,0,.28); */
    transition: all 0.25s;
  }
  
  .veen button.dark {
    border-color: linear-gradient(90deg, hsla(134, 87%, 42%, 1) 5%, hsla(56, 97%, 56%, 1) 86%);
    background: linear-gradient(90deg, hsla(134, 87%, 42%, 1) 5%, hsla(56, 97%, 56%, 1) 86%);
  }
  
  .veen .move button.dark {
    border-color:linear-gradient(90deg, hsla(56, 97%, 56%, 1) 5%, hsla(134, 87%, 42%, 1) 86%);;
    background:  linear-gradient(90deg, hsla(56, 97%, 56%, 1) 5%, hsla(134, 87%, 42%, 1) 86%);
  }
  
  .veen .splits p {
    font-size: 18px;
  }
  
  .veen button:active {
    box-shadow: none;
  }
  
  .veen button:focus {
    outline: none;
  }
  
  .veen>.wrapper {
    position: absolute;
    width: 40%;
    height: 120%;
    top: -10%;
    left: 5%;
    background: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
    transition: all 0.5s;
    color: #303030;
    overflow: hidden;
  }
  
  .veen .wrapper>form {
    padding: 15px 30px 30px;
    width: 100%;
    transition: all 0.5s;
    background: #fff;
    width: 100%;
  }
  
  .veen .wrapper>form:focus {
    outline: none;
  }
  
  .veen .wrapper #login {
    padding-top: 20%;
    visibility: visible;
  }
  
  .veen .wrapper #register {
    transform: translateY(-80%) translateX(100%);
    visibility: hidden;
  }
  
  .veen .wrapper.move #register {
    transform: translateY(-80%) translateX(0%);
    visibility: visible;
  }
  
  .veen .wrapper.move #login {
    transform: translateX(-100%);
    visibility: hidden;
  }
  
  .veen .wrapper>form>div {
    position: relative;
    margin-bottom: 15px;
  }
  
  .veen .wrapper label {
    position: absolute;
    top: -7px;
    font-size: 12px;
    white-space: nowrap;
    background: #fff;
    text-align: left;
    left: 15px;
    padding: 0 5px;
    color: #999;
    pointer-events: none;
  }
  
  .veen .wrapper h3 {
    margin-bottom: 25px;
  }
  
  .veen .wrapper input {
    height: 40px;
    padding: 5px 15px;
    width: 100%;
    border: solid 1px #999;
  }
  
  .veen .wrapper input:focus {
    outline: none;
    border-color: 90deg,linear-gradient(90deg, hsla(134, 87%, 42%, 1) 5%, hsla(56, 97%, 56%, 1) 86%);
  }
  
  .veen>.wrapper.move {
    left: 45%;
  }
  
  .veen>.wrapper.move input:focus {
    border-color: linear-gradient(90deg, hsla(179, 67%, 66%, 1) 0%, hsla(238, 74%, 61%, 1) 100%);
  }
  
  @media (max-width: 767px) {
    .veen {
      padding: 5px;
      margin: 0;
      width: 100%;
      display: block;
    }
    .veen>.wrapper {
      position: relative;
      height: auto;
      top: 0;
      left: 0;
      width: 100%;
    }
    .veen>div {
      display: inline-block;
    }
    .splits {
      width: 50%;
      background: #fff;
      float: left;
    }
    .splits button {
      width: 100%;
      border-radius: 0;
      background: #505050;
      border: 0;
      opacity: 0.7;
    }
    .splits button.active {
      opacity: 1;
    }
    .splits button.active {
      opacity: 1;
      background:linear-gradient(90deg, hsla(134, 87%, 42%, 1) 5%, hsla(56, 97%, 56%, 1) 86%);
    }
    .splits.rgstr-btn button.active {
      background:linear-gradient(90deg, hsla(56, 97%, 56%, 1) 5%, hsla(134, 87%, 42%, 1) 86%);
    }
    .splits p {
      display: none;
    }
    .veen>.wrapper.move {
      left: 0%;
    }
  }
  
  input:-webkit-autofill,
  textarea:-webkit-autofill,
  select:-webkit-autofill {
    box-shadow: inset 0 0 0 50px #fff;
  }
  